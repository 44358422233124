/* eslint-disable */

// TODO: Ignore linting this file. Our linter doesn't like the < from React right now. Need to investigate
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {ThemeProvider, createTheme} from '@mui/material';
import { QueryClient, QueryClientProvider } from "react-query";

const rootEl = document.getElementById('root' ) as Element;
const root = ReactDOM.createRoot( rootEl );

// This theme business is how you override the default material ui colors
var r = document.querySelector(':root') as HTMLElement;
r.style.setProperty('--primary-color',  window.bookingPageData.primary_color ) 
const theme = createTheme( {
  palette: {
    primary: {
      main: window.bookingPageData.primary_color
    }
  },
  typography: {
    fontFamily: 'Roboto',
    h1: {fontSize: '44px', fontWeight: 400},
    h2: {fontSize: '33px', fontWeight: 400},
    h3: {fontSize: '22px', fontWeight: 400},
    h4: {fontSize: '18px', fontWeight: 400},
    // Have to make this 18px because calendar plugin uses h6
    //h6: {fontSize: '18px', fontWeight: 400},
    subtitle1: {color: 'gray'},
    body2: { fontSize: '14px', fontWeight: 600}
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: window.bookingPageData.primary_color,
          transformOrigin: 'bottom left',
          fontSize: '16px',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          textAlign: 'left'
        }
      }
    },
    MuiNativeSelect : {
      styleOverrides: {
        multiple: {
          paddingTop: '40px',
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          paddingTop: '20px'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '0',
          color: "#bf3333"
        }
      }
    }
  }
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  }
});

root.render(
  <div>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <App/>
      </ThemeProvider>
    </QueryClientProvider>
    
  </div>
);
