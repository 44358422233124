import React from 'react';
import { Grid, Typography } from '@mui/material';
import { FaRegUser, FaRegClock } from 'react-icons/fa';
import './Header.css';

declare interface HeaderProps {
    subheader?: string
    isConfirmation: boolean
    title?: string
}
export function Header( props: HeaderProps ) {
    let {subheader, isConfirmation } = props;
    // this object destructuring not working ugh
    // let {logo, logoLink, title, duration, host} = window.bookingPageData
    let logo = window.bookingPageData.company_logo,
        logoLink = window.bookingPageData.company_logo_link,
        title = window.bookingPageData.title,
        duration = window.bookingPageData.duration,
        //@TODO: Confirm this property still works
        host = window.bookingPageData.hosts;

    // The duration will be in seconds, convert to hours with the remainder shown in minutes
    const durationNum = Number( duration );
    const hours = Math.floor( durationNum / 3600 ),
        remainingSecondsAfterHours = durationNum % 3600,
        minutes = remainingSecondsAfterHours / 60;

    let durationStr = '';
    
    if ( hours ) {
        let unit = ( hours > 1 ) ? 'hours' : 'hour';
        durationStr += hours + ' ' + unit;
    }
    
    if( minutes ) {
        let unit = ( minutes > 1 ) ? 'minutes' : 'minute';

        // Add a space if hours were already appended
        if( durationStr ) {
            durationStr += ' ';
        }

        durationStr += minutes + ' ' + unit;
    }

    const headerVariation = ( isConfirmation ) ? 'h1' : 'h2';

    return (
        <Grid container alignItems="center" direction="column" className="header-wrapper" component="div">
            { logo ?
                <Grid item xs={12} alignItems="center" component="div">
                    <a href={logoLink} target="_blank" rel="noreferrer"><img className = 'header-logo' src={logo}></img> </a>
                </Grid> :
            '' }

            <Grid item xs={12}>
                <Typography variant={headerVariation} className="header__text"> { ( isConfirmation ) ? 'Confirmed' : title } </Typography>
            </Grid>

            <Grid container direction="row" alignItems="center" component="div">
            <Grid item xs={12} >
                <div className={`sub-header ${ isConfirmation ? 'confirmation' : ''}`}>
                    { isConfirmation ?
                        <Typography variant="h4" >{subheader}</Typography> : 
                        <>
                        { host ? 
                            <span className='sub-header'>
                            <FaRegUser className='sub-header-host-icon'/>
                            <Typography className='sub-header-item' variant="subtitle1" >{host}</Typography> 
                            </span> :
                            <span></span>
                        }
                        <span className='sub-header'>
                            <FaRegClock className='sub-header-host-icon'/>
                            <Typography className='sub-header-item' variant="subtitle1">  {durationStr}</Typography>
                        </span>
                        </>
                    }
                    
                </div>
                <div className={`header-divider ${ isConfirmation ? 'header-divider--reduced' : '' }`}></div>
            </Grid>
            </Grid>

     </Grid>
    );
}

