import React from 'react';
import { useRef, RefObject, useEffect, useState } from 'react';
import {useCalendar, useLocale} from 'react-aria';
import {useCalendarState } from 'react-stately';
import {createCalendar} from '@internationalized/date';
import { CalendarButton } from '../Button/Button';
import { CalendarGrid } from '../CalendarGrid/CalendarGrid';
import { Typography, Grid } from '@mui/material';
import { CalendarProps, DateValue } from '@react-types/calendar';
import { isCurrentMonth, isFutureLimitMonth } from '../../utils/dateTimeHelpers';

import './Calendar.css';

type CustomAriaCalendarProps<Date> =  CalendarProps<DateValue> & {
  month: string;
  date: string
  isFetching: boolean;
  textColor: string;
  primaryColor: string;
  availableDays : [];
  defaultValue: any
  onChange: (ev: any ) => void
  onFocusChange: (ev: any ) => void
}
export function Calendar(props: CustomAriaCalendarProps<Date>) {
    let { locale } = useLocale();
    let state = useCalendarState( {
        ...props,
        locale,
        createCalendar
    } );

  const [ prevButtonDisabled, setPrevButtonDisabled ] = useState( true );
  const [ nextButtonDisabled, setNextButtonDisabled ] = useState( false );

    let ref = useRef() as RefObject<HTMLDivElement>;
    let { calendarProps, prevButtonProps, nextButtonProps, title } = useCalendar(
        props,
        state
    );

    useEffect( ()=> {
        const updatedDate = new Date();
        updatedDate.setFullYear( state.focusedDate.year );
        updatedDate.setMonth( state.focusedDate.month - 1 );

        setPrevButtonDisabled( isCurrentMonth( updatedDate ) );
        setNextButtonDisabled( isFutureLimitMonth( updatedDate ) );
    }, [state.focusedDate]);

    return (
        <Grid container direction="row" alignItems="center" className="calendar-grid-wrapper">
            {/* @ts-ignore */}
            <div {...calendarProps} ref={ref} className={'calendar'} style={props.isFetching ? {pointerEvents: 'none'} : {}}>
                {props.isFetching ? <div className="booking-page__loading-calendar"></div> : null}
                <div className="header">
                    <Grid container direction="row" alignItems="center">
                        <Grid item md={1} lg={1} xs={1} alignItems="center">
                            <CalendarButton {...prevButtonProps} isDisabled={prevButtonDisabled}>&lt;</CalendarButton>
                        </Grid>
                        <Grid item md={10} lg={10} xs={10} alignItems="center" style={{display:'flex', justifyContent:'center'}}>
                            <Typography variant="h6" className="month-title">{title} </Typography>
                        </Grid>
                        <Grid item md={1} lg={1} xs={1} alignItems="center">
                            <CalendarButton {...nextButtonProps} isDisabled={nextButtonDisabled}>&gt;</CalendarButton>
                        </Grid>
                    </Grid>
                </div>
                <CalendarGrid state={state} textColor={props.textColor} primaryColor={props.primaryColor} availableDays={props.availableDays}/>
            </div>
        </Grid>
    );
}

export default Calendar;