import React from 'react';
import {useCalendarCell, AriaCalendarCellProps} from 'react-aria';
import { useRef, useState, RefObject } from 'react';
import './CalendarCell.css';
import {CalendarState} from 'react-stately';

interface CustomAriaCalendarCellProps extends AriaCalendarCellProps {
  state: CalendarState;
  textColor: string;
  primaryColor: string
  availableDays: []
}

export function CalendarCell(props: CustomAriaCalendarCellProps) {
  const { state, date, availableDays } = props
  let overrideDisable = undefined;

  const availableDaysInteger = availableDays.map( day => parseInt(day) )
  if ( !availableDaysInteger.includes( date.day ) ) {
    overrideDisable = true;
  }
  let ref = useRef() as RefObject<HTMLTableDataCellElement>;;
  let {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    isUnavailable,
    formattedDate
  } = useCalendarCell({ date: date, isDisabled: overrideDisable }, state, ref);

  const [isHovered, setHover] = useState( false );
  const handleHover = ( changeToHover: boolean) => {
    if ( changeToHover ) {
      setHover( true )
    } else {
      setHover( false )
    }
  }
  
  return (
    /* @ts-ignore */
    <td {...cellProps} >
      
      {/* @ts-ignore */}
      <div {...buttonProps} ref={ref} hidden={isOutsideVisibleRange} className={`cell ${isHovered ? 'hovered' : ''} ${isSelected ? 'selected' : ''} ${isDisabled ? 'disabled' : '' } ${isUnavailable ? 'unavailable' : ''}`} onMouseEnter={() => {handleHover( true )}} onMouseLeave={() => {handleHover(false);}}>
        {formattedDate}
      </div>
    </td>
  );
}