import React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';

export declare interface ResponseDialogProps {
    onClose: () => void;
    onButtonClick ?:  () => void;
    open: boolean
    title?: string
    text?: string
    buttonText?: string
}
export function ResponseDialog( props: ResponseDialogProps ) {
    let { onClose, onButtonClick, open, title, text, buttonText } = props;
    text = text ? text : 'Something went wrong. Wait a minute and try again.';
    buttonText = buttonText ? buttonText : 'Okay';
    onButtonClick = onButtonClick ? onButtonClick : onClose;


    return (
        <Dialog onClose={onClose} open={open}>
            
                    <DialogTitle>
                        {
                            ( title ) ? <Typography variant="h6">{title}</Typography>  : ''
                        }
                    </DialogTitle> 
            
            

            <DialogContent>
                <DialogContentText>
                    <Typography variant="body1"> {text} </Typography>
                </DialogContentText>
            </DialogContent>
            
            <DialogActions>
                <Button variant="contained" onClick={onButtonClick}> {buttonText} </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ResponseDialog;