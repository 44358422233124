// For some reason, ESLint chokes on this file. Seemed to happen when I added moment...
import { timezones } from '../data/dateMaps';
import moment from 'moment-timezone';

export function buildTimeStringFromNumbers( hour : number, minutes : number ) {

    if ( window.bookingPageData.time_format === '12-hour' ) {
        let meridiem = 'AM';
        if ( hour > 11 ) {
            meridiem = 'PM';
        }
        if ( hour > 12 ) {
            hour = hour - 12;
        } else if ( hour == 0 ) {
            hour = 12;
        }

        if ( minutes < 10 ) {
            // Add the zero if minutes less than 10.
            return `${hour}:0${minutes} ${meridiem}`;
        }
        return `${hour}:${minutes} ${meridiem}`;
    } else {
        if ( minutes < 10 ) {
            // Add the zero if minutes less than 10.
            return `${hour}:0${minutes}`;
        } else {
            return `${hour}:${minutes}`;
        }
    }
}

function buildFormattedTime( date : Date, tz ?: string ) {
    let timeptions : Intl.DateTimeFormatOptions = {
            hour: 'numeric',
            minute: 'numeric'
        };

    if ( tz ) {
        timeptions.timeZone = tz;
    }
    
    return new Intl.DateTimeFormat( "en-US", timeptions ).format( date );
}

function buildEndTime( startDateTime : Date, duration : number ) {
    let endTimestamp = startDateTime.getTime() + ( duration * 1000 );
    return new Date( endTimestamp );
}

function buildMonthName( date : Date, tz ?: string ) {
    let options : Intl.DateTimeFormatOptions = {
        month: 'long'
    };

    if ( tz ) {
        options.timeZone = tz;
    }
    return new Intl.DateTimeFormat( "en-US", options ).format( date );
}

function getNumeralSuffix( targetNumber : number ) {
    if ( targetNumber > 10 && targetNumber < 14 ) {
        // 11th - 13th are unique cases that don't match the common pattern
        return 'th';
    } else {
        const mod10Number = targetNumber % 10;

        if ( mod10Number === 1 ) {
            return 'st';
        } else if ( mod10Number === 2 ) {
            return 'nd';
        } else if ( mod10Number === 3 ) {
            return 'rd';
        }
    }

    return 'th';
}

function buildDayOfMonth( date : Date, tz ?: string ) {
    let options : Intl.DateTimeFormatOptions = {
        day: 'numeric'
    };

    if ( tz ) {
        options.timeZone = tz;
    }
    const dayOfMonth = parseInt( new Intl.DateTimeFormat( "en-US", options ).format( date ) );
    return dayOfMonth + getNumeralSuffix( dayOfMonth );
}

export function buildTimeStartEndString( date : Date, duration : number, tz ?: string ) {
    // Get start time
    const startingHour = buildFormattedTime( date, tz );

    // Get end time
    let endTime = buildEndTime( date, duration );
    const endingHour = buildFormattedTime( endTime, tz );

    return `${startingHour} - ${endingHour}`;
}

export function buildWeekdayName( date : Date, tz ?: string ) {
    let options : Intl.DateTimeFormatOptions = {
        weekday: 'long'
    };

    if ( tz ) {
        options.timeZone = tz;
    }
    return new Intl.DateTimeFormat( "en-US", options ).format( date );
}

export function buildMonthDayString( date : Date, tz ?: string ) {
    const monthName = buildMonthName( date, tz ),
        dayOfMonth = buildDayOfMonth( date, tz );

    return `${monthName} ${dayOfMonth}`;
}

export function buildFullDateString( date : Date, tz ?: string ) {
    let options : Intl.DateTimeFormatOptions = {
        year: 'numeric'
    };

    if ( tz ) {
        options.timeZone = tz;
    }

    const year = new Intl.DateTimeFormat( "en-US", options ).format( date )

    return `${buildMonthDayString( date, tz )}, ${year}`;
}

export function getBrowserTimezone() {
    return moment.tz.guess();
}

export function buildTimezoneString( date ?: Date, tz ?: string ) {

    if ( !date ) {
        date = new Date();
    }

    let timezone = tz || getBrowserTimezone();

    const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone: tz,
        timeZoneName: 'long',
      });

    // Replace underscores with spaces
    timezone = timezone.replace( /_/g, ' ' );

    // Pull the timezone string from the end of the date string (found in parenthesis)
    // E.g. Tue May 30 2023 11:10:42 GMT-0700 (Pacific Daylight Time) => Pacific Daylight Time
    const dateString = formatter.format(date);
    const regExp = /,\s(.*)/gi;
    const matches = regExp.exec( dateString );

    if ( matches && matches.length > 1 ) {
        timezone += ` (${matches[ 1 ]})`;
    }

    return timezone;
}

export function isCurrentMonth( date : Date ) {
    const currentDate = new Date();
    return currentDate.getFullYear() === date.getFullYear() && currentDate.getMonth() === date.getMonth();
}

/**
 * Take in a date and calculate if the month matches the month that our future limit ends at.
 * The future limit can be months, weeks, or days. The last possible event falls on the future limit's
 * date relative to the current date. This function determines if the future limit's final day has a month
 * that matches the month of the date passed in.
 * @param date
 * @returns boolean
 */
export function isFutureLimitMonth( date : Date ) {
    if ( window.bookingPageData.future_limit ) {
            let futureDate = getFutureLimitDate();

            // Compute our calculated future limit date to the date passed in, see if it is the same month
            return futureDate.getFullYear() === date.getFullYear() && futureDate.getMonth() === date.getMonth();
    }

    return false;
}

export function getFutureLimitDate() {
    let futureDate = new Date();
    if ( window.bookingPageData.future_limit ) {
        // Try catch just incase because we are JSON parsing
        try {
            const futureLimitData = JSON.parse( window.bookingPageData.future_limit );
            switch ( futureLimitData.units ) {
                case 'Months':
                    futureDate.setMonth( futureDate.getMonth() + futureLimitData.value );
                    break;
                case 'Weeks':
                    // Take the number of weeks and expand it into milliseconds
                    const weeksToAdvance = futureLimitData.value * 7 * 24 * 60 * 60 * 1000;
                    // Take the current date and advance it by the number of weeks in milliseconds
                    futureDate = new Date( futureDate.getTime() + weeksToAdvance );
                    break;
                case 'Days':
                    // Take the number of days and expand it into milliseconds
                    const daysToAdvance = futureLimitData.value * 24 * 60 * 60 * 1000;
                    // Take the current date and advance it by the number of days in milliseconds
                    futureDate = new Date( futureDate.getTime() + daysToAdvance );
                    break;
            }
        } catch ( e ) {
            // JSON parse failed for some reason.
            // In this case do nothing and fallback to returning false below
        }
    }
    return futureDate;
}

export function getTimezones() {
    return JSON.stringify( timezones );
}

export function getRelativeTimezoneOffset( timezone : string = 'UTC' ) {
    const date = new Date();
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
    
    return Math.round((date.getTime() - tzDate.getTime()) / 6e4);
}