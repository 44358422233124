import React, {useState, useEffect } from 'react';
import {Form} from '../Form/Form';
import { Header } from '../Header/Header';
import {ApptSummary} from '../ApptSummary/ApptSummary';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { buildWeekdayName, buildFullDateString, buildTimeStartEndString, getFutureLimitDate } from '../../utils/dateTimeHelpers';
import { Markup } from 'interweave';
import './BookingFormPage.css';

export function BookingFormPage() {

    const navigate = useNavigate();

    // Get confirmed time information off of path
    const [ searchParams ] = useSearchParams();
    const rescheduleParam = searchParams.get( 'reschedule' );
    const rescheduleHashParam = searchParams.get( 'hash' );

    let timeParam = searchParams.get( 'time' ) as string;
    let timestamp =  Number( timeParam );

    let timezoneParam = searchParams.get( 'tz' ) as string;

    const appTime = new Date( timestamp );

    const isDate = appTime instanceof Date;

    if ( !timeParam || !isDate || isNaN(appTime.getTime()) ) {
        // Bail out and go back if the appt time from the url has been tampered with
        navigate( '/' );
    }

    // If the time selected is outside the bounds, navigate back to picker page
    if( appTime.getTime() < ( new Date() ).getTime() || appTime.getTime() > getFutureLimitDate().getTime() ) {
        navigate( '/' );
    }

    // Name of the weekday (such as 'Friday')
    const weekdayName = buildWeekdayName( appTime, timezoneParam );

    // The date as a full string such as "June 8th, 2023"
    const fullDateString = buildFullDateString( appTime, timezoneParam );
    
    // Added a fallback of 30 min duration incase duration isn't present.
    const duration = window.bookingPageData.duration || 1800;

    // The start/end time in the format: '8:00AM - 9:00AM'
    const timeStartEndString = buildTimeStartEndString( appTime, duration, timezoneParam );

    const [ isFormSubmitting, setIsFormSubmitting ] = useState( false );

    // Click handler for back btn, but wait for the exit animation to finish. Otherwise you wont see it.
    const handleClickBack = () => {
        setSlideIn( false );
        setTimeout( () => {
            let homepageRoute = '/';
            const monthParam = searchParams.get( 'month' );
            const dateParam = searchParams.get( 'date' );
            const tzParam = searchParams.get( 'tz' );
            if( monthParam && dateParam && typeof monthParam === 'string' && typeof dateParam === 'string' ) {
                homepageRoute += `?month=${monthParam}&date=${dateParam}&tz=${tzParam}`
            }
            navigate( homepageRoute );
        }, 500)
    };

    // Set up the slide in on first render
    const [ slideIn, setSlideIn ] = useState( false )
    useEffect( () => {
        setSlideIn( true );
    }, [] )

    return (
        <>
            <Header isConfirmation={false}/>
            <div className={`bookingFormPage-wrapper  ${slideIn ? 'slide-in-right' : '' }` }>
                <div className="col-wrapper col-wrapper--wider">
                    <div className="col-wrapper__column-left">
                        <ApptSummary
                            className="appt-summary"
                            weekdayName = {weekdayName}
                            fullDateString = {fullDateString}
                            timeStartEndString = {timeStartEndString}
                            time={timestamp}
                            tz={timezoneParam}
                            handleClickBack ={handleClickBack}
                        />
                    </div>
                    <div className="col-wrapper__column-right">
                        <Form
                            time={timestamp}
                            tz={timezoneParam}
                            reschedule = {rescheduleParam ? rescheduleParam : ''}
                            rescheduleHash = {rescheduleHashParam ? rescheduleHashParam : ''}
                            formSubmittingHandler = {setIsFormSubmitting}
                        ></Form>
                        { ( window.bookingPageData.booking_details) ? <div className="booking-form-page__cancellation-message"><Markup content={ window.bookingPageData.booking_details }/></div> : '' }
                    </div>

                </div>
            </div>
            { isFormSubmitting ? <div className="booking-form-page__loading-wrapper"><div className="booking-page__loading-calendar"></div></div> : null}
        </>
    );
}

export default BookingFormPage;
