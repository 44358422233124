import React from 'react';
import './App.css';
import {
Route,
HashRouter,
Routes
} from 'react-router-dom';
import {BookingTimeSelectPage } from './components/BookingTimeSelectPage/BookingTimeSelectPage';
import { FormValues } from './components/Form/Form';
import { getTextColor } from './utils/colorMath';
import BookingFormPage from './components/BookingFormPage/BookingFormPage';
import BookingConfirmationPage from './components/BookingConfirmationPage/BookingConfirmationPage';

declare global {
    interface Window {
        bookingPageData: {
            hosts: []
            primary_color: string;
            duration: number;
            company_logo: string;
            company_logo_link: string;
            title: string;
            id: string | number;
            custom_fields ?: Array<string>;
            submit_button_text: string
            form_settings: any;
            // From here down are items that we add after the save response
            eventTime: string;
            eventDate: string;
            eventLocation: string; 
            hostName: string;
            time_format: string;
            future_limit : string;
            prefills ?: FormValues;
            dateTimestamp ?: number;
            booking_details ?: string;
            timezone: string;
        }
    }
}

const isSingleHost = window.bookingPageData.hosts ? true : false;
const primaryColor = window.bookingPageData.primary_color ?? '#1285ff';
const textColor = getTextColor( primaryColor );

// Set text color as CSS var
var r = document.querySelector(':root') as HTMLElement;
r.style.setProperty('--text-color', textColor || '#ffffff' ); 

function App() {
    return (
        <div className="app-wrapper">
            <div className="app-inner-wrapper">
                <HashRouter>
                    <Routes>
                        <Route path="/form" element={<BookingFormPage/>}/>
                        <Route path="/thank-you" element={<BookingConfirmationPage />}/>
                        <Route path="*" element={<BookingTimeSelectPage isSingleHost={isSingleHost} textColor={textColor} primaryColor={primaryColor}/>}/>
                    </Routes> 
                </HashRouter>
            </div>
        </div>
    );
}

export default App;
