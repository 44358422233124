import { useReducer } from 'react';

import { getBrowserTimezone } from '../utils/dateTimeHelpers';

interface QueryParams {
    monthQuery : any;
    dateQuery ?: any;
    yearQuery ?: any;
    tzQuery ?: any;
}
export interface InitialState {
    month : string;
    date : string;
    year : string;
    time : string;
    tz : string;
    confirmedTime : string;
    availableTimes : [];
    availableDays : [];
}

interface DispathObject {
    type : string;
    // Allows us to call the dispatch function and allow nulling out values
    [key : string] : any;
}

function reducer( state : InitialState, action : DispathObject ) : InitialState {
    switch ( action.type ) {
        case 'changed_month': {
            const newState = {
                ...state,
                month: action.month,
                year: action.year
            };

            if ( state.date ) {
                newState.date = '';
            }

            return newState;
        }
        case 'changed_year': {
            return {
                ...state,
                year: action.year
            };
        }
        case 'changed_date': {
            return {
                ...state,
                date: action.date
            };
        }
        case 'changed_time': {
            return {
                ...state,
                time: action.time
            };
        }
        case 'changed_confirmed_time': {
            return {
                ...state,
                confirmedTime: action.confirmedTime
            };
        }
        case 'changed_available_times': {
            return {
                ...state,
                availableTimes: action.availableTimes
            };
        }
        case 'changed_available_days': {
            return {
                ...state,
                availableDays: action.availableDays
            };
        }
        case 'changed_timezone': {
            return {
                ...state,
                tz: action.tz
            };
        }
        default:
            throw new Error();
    }
}

function createInitialState( queryParams : QueryParams ) : InitialState {
    // Initial state is the month and date grabbed from the url search param "month" and "date"
    return {
        month: queryParams.monthQuery,
        date: queryParams.dateQuery,
        year: queryParams.yearQuery,
        time: '',
        tz: queryParams.tzQuery || getBrowserTimezone(),
        confirmedTime: '',
        availableTimes: [],
        availableDays: []
    };
}

export const ACTIONS = {
    CHANGED_MONTH: 'changed_month',
    CHANGED_DATE: 'changed_date',
    CHANGED_TIME: 'changed_time',
    CHANGED_CONFIRMED_TIME: 'changed_confirmed_time',
    CHANGED_AVAIL_TIMES: 'changed_available_times',
    CHANGED_AVAIL_DAYS: 'changed_available_days',
    CHANGED_TIMEZONE: 'changed_timezone'
};

export const GetReducer = ( initialState : QueryParams ) => {
    return useReducer( reducer, initialState, createInitialState );
  };
