import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Controller, ControllerRenderProps } from "react-hook-form";
import {UseFormRegisterReturn} from "react-hook-form";

interface BasicDatePickerProps {
  label: string
  name: ControllerRenderProps['name'], 
  // This is from react-use-hook-form
  control: any
  helperText?: string
  inputProps: UseFormRegisterReturn<string>;
  isDateTime ?: boolean
}
export function BasicDatePicker(props: BasicDatePickerProps) {

  return (
    /*Getting this error on the Controller component.. TS2589: Type instantiation is excessively deep and possibly infinite. The error starts to happen as soon as there's more than 45 namespaces.Seems like an issue TS is working out so ignoring for now */
    /* @ts-ignore */
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={( new Date() ).toLocaleDateString('en-US')}
      render={({ field, fieldState, formState}) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {props.isDateTime ? 
              <DateTimePicker
              label={<span title={props.label}>{props.label}</span>}
              {...field}
              renderInput={(params:any) => { return <TextField variant="filled" {...params} fullWidth /> }}
              
              /> :

              <DatePicker
              label={<span title={props.label}>{props.label}</span>}
              {...field}
              renderInput={(params:any) => { return <TextField variant="filled" {...params} fullWidth /> }}
              /> }

        </LocalizationProvider>
      )}
    />
  );
}