import React from 'react';
import './BookingConfirmationPage.css';
import { Header } from '../Header/Header';
import { IconApptSummary } from '../IconApptSummary/IconApptSummary';
import { buildWeekdayName, buildFullDateString, buildTimeStartEndString } from '../../utils/dateTimeHelpers';

export function BookingConfirmationPage() {
    let eventTitle = window.bookingPageData.title,
        eventLocation = window.bookingPageData.eventLocation,
        hostName = window.bookingPageData.hostName,
        timezone = window.bookingPageData.timezone;

    let appTime = new Date();
    // if dateTimestamp isn't present, default to empty string so IconApptSummary component knows to hide date info
    let eventDate = '';
    let eventTime = '';

    if( window.bookingPageData.dateTimestamp ) {
        appTime = new Date( window.bookingPageData.dateTimestamp );


        // Name of the weekday (such as 'Friday')
        const weekdayName = buildWeekdayName( appTime, timezone );

        // The date as a full string such as "June 8th, 2023"
        const fullDateString = buildFullDateString( appTime, timezone );

        eventDate = `${weekdayName} ${fullDateString}`;
        
        // Added a fallback of 30 min duration incase duration isn't present.
        const duration = window.bookingPageData.duration || 1800;
        // The start/end time in the format: '8:00AM - 9:00AM'
        eventTime = buildTimeStartEndString( appTime, duration, timezone );        
    } 

    return (
        <>
            <Header isConfirmation={true} title="Confirmed" subheader={( hostName ) ? `You're booked with ${hostName}` : 'You\'re booked'}/>
            <IconApptSummary
                eventTitle={eventTitle}
                eventTime={eventTime}
                eventDate={eventDate}
                eventLocation={eventLocation}
            >
            </IconApptSummary>
            <p className="booking-confirmation-page__disclaimer">Check your email for a confirmation message, where you can reschedule if necessary.</p>
           
        </>
    );
}

export default BookingConfirmationPage;
