import React from 'react';
import {useCalendarGrid, AriaCalendarGridProps} from 'react-aria';
import {getWeeksInMonth} from '@internationalized/date';
import {useLocale} from 'react-aria';
import {CalendarCell} from '../CalendarCell/CalendarCell';
import {CalendarState} from 'react-stately';

interface CustomAriaCalendarGridProps extends AriaCalendarGridProps {
  state: CalendarState
  textColor: string;
  primaryColor: string;
  availableDays: []
}
export function CalendarGrid( props: CustomAriaCalendarGridProps ) {
  const { state, textColor, primaryColor, availableDays} = props
  let { locale } = useLocale();
  let { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);

  // Get the number of weeks in the month so we can render the proper number of rows.
  let weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

  return (
    /* @ts-ignore */
    <table {...gridProps}>

      {/* @ts-ignore */}
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, index) => <th key={index}>{day}</th>)}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state.getDatesInWeek(weekIndex).map((value: any, i: number) => (
              value
                ? (
                  <CalendarCell
                    key={i}
                    state={state}
                    date={value}
                    textColor = {textColor}
                    primaryColor={primaryColor}
                    availableDays={availableDays}
                  />
                )
                : <td key={i} />
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}