import React from 'react';
import { useState } from 'react';
import { Button, Typography, Grid } from '@mui/material';
import { CustomSelect } from '../CustomSelect/CustomSelect';
import { buildTimezoneString, buildWeekdayName, buildMonthDayString, buildTimeStringFromNumbers, getBrowserTimezone,getTimezones } from '../../utils/dateTimeHelpers';
import './TimePicker.css';

declare interface TimePickerProps {
    time?: string
    month: string
    date: string
    year: string
    timezone: string
    handleTimeClicked: (ev: any) => void
    handleTimeConfirmed: (ev: any) => void
    handleTimezoneChanged: (ev: any) => void
    textColor: string
    availableTimes: Array<string>
    isFetching: boolean

}


export function TimePicker( props: TimePickerProps ) {
    let { time, month, date, year, handleTimeClicked, handleTimeConfirmed, handleTimezoneChanged, textColor, availableTimes, isFetching } = props;
    const selectedTime = time;

    const dateObject = new Date( Number(year), Number(month) - 1, Number(date) );

    const [ timezoneOpen, setTimezoneOpen ] = useState( false );

    const openTimezoneSelect = () => {
        setTimezoneOpen( true );
    };

    const handleClose = () => {
        setTimezoneOpen(false);
      };

    const timezoneDisplayString = buildTimezoneString(dateObject, props.timezone);

    if ( date ) {
        return (
            <Grid container className="time-picker" style={props.isFetching ? {pointerEvents: 'none', position:'relative', minHeight: '300px' } : { position:'relative', minHeight: '200px' }} >
                {isFetching? <div className="booking-page__loading-calendar"></div> : null }
                <Grid item xs={12} style={{marginTop: '2px', marginBottom: '2px'}}>
                    <Typography variant="h6" align="center">
                        {buildWeekdayName(dateObject)}, {buildMonthDayString(dateObject)}
                    </Typography>
                </Grid>
            
                <div className="time-list">
                    {availableTimes.map( (time, i) => {
                        // Remove the seconds for display purposes, then split the time apart on semi-colon
                        let splitTime = time.substring(0, time.length - 3).split( ':' );

                        let displayTime = buildTimeStringFromNumbers( parseInt(splitTime[ 0 ]),parseInt(splitTime[ 1 ]) );

                        return (
                        <div className="time-button-wrapper">
                            <div className={selectedTime === time ? 'time-button--selected' : 'time-button' }>
                                <Button component="button" className ={selectedTime === time ? "time-btn selected" : 'time-btn'} color="primary" variant="outlined" value={time} key={i} onClick={handleTimeClicked}>
                                    <Typography variant='body1'> {displayTime} </Typography>
                                </Button>
                            </div>
                            <div className={selectedTime === time ? 'time-button--selected' : 'confirm-button' }>
                                <Button component="button" className= { selectedTime === time ? "confirm-btn selected" : "confirm-btn display-none" } color="primary" variant="contained"  onClick={handleTimeConfirmed} style={selectedTime === time ? {color: textColor} : {} }>
                                <Typography variant='body1'>CONFIRM</Typography>
                                </Button>
                            </div>
                        </div> 
                    )
                    })}

                </div>
                { availableTimes.length ? <div className="time-picker__timezone" onClick={openTimezoneSelect}><span>{timezoneDisplayString}</span></div> : '' }
                { availableTimes.length ? <div className="time-picker__timezone-select"><CustomSelect
                    name="timezone"
                    prefill={ props.timezone }
                    menu={ getTimezones() }
                    type="drop"
                    open={ timezoneOpen }
                    onClose={ handleClose }
                    onChange={handleTimezoneChanged}
                /></div> : '' }
            </Grid>
        )
    } else {
        return (
            <div className="time-picker__empty-wrapper"> 
                <Typography className="time-picker__empty-text" variant="subtitle1">Select a date <span className="time-picker__empty-text-direction--desktop">on the left</span><span className="time-picker__empty-text-direction--mobile">above</span> to view available times</Typography>
            </div>
        )
    }

}

export default TimePicker;