import React from 'react';
import { useRef, RefObject } from "react";
import { useButton } from "@react-aria/button";
import { useFocusRing } from "@react-aria/focus";
import { mergeProps } from "@react-aria/utils";
import { MdChevronLeft } from "react-icons/md";
import { MdChevronRight } from "react-icons/md"; 

import './Button.css'

export function CalendarButton( props: any ) {
  let ref = useRef() as RefObject<HTMLButtonElement>;
  let { buttonProps } = useButton(props, ref);
  let { focusProps, isFocusVisible } = useFocusRing();
  return (
    /* @ts-ignore */
    <span
      {...mergeProps(buttonProps, focusProps)}
      ref={ref}
      className={`p-2 rounded-full calendar-button ${props.isDisabled ? "text-gray-400" : ""} ${
        !props.isDisabled ? "hover:bg-violet-100 active:bg-violet-200" : ""
      } outline-none ${
        isFocusVisible ? "ring-2 ring-offset-2 ring-purple-600" : ""
      }`}
    >
      {props.children === '<' ? <MdChevronLeft/> : <MdChevronRight/>  }

    </span>
  );
}
