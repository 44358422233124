import React from 'react';
import {Typography} from '@mui/material';
import { MdChevronLeft } from 'react-icons/md';
import { buildTimezoneString } from '../../utils/dateTimeHelpers';
import './ApptSummary.css';

interface ApptSummaryProps {
    weekdayName: string;
    fullDateString: string;
    timeStartEndString: string;
    handleClickBack: (getNewMonth: any) => void;
    className ?: string;
    time : number;
    tz : string;
}
export function ApptSummary( props: ApptSummaryProps ) {
    let { weekdayName, fullDateString, timeStartEndString, handleClickBack } = props;

    return (
        <div className="text-align-center">
            <div>
                <Typography variant='h3'> {weekdayName} </Typography>
            </div>
            <div className="margin-bottom-20">
                <Typography variant='h3'> {fullDateString} </Typography>
            </div>
            <div className="margin-bottom-20">
                <Typography variant='body1'> {timeStartEndString} </Typography>
            </div>
            <div className="margin-bottom-20 appt-summary__timezone">
                <Typography variant='body2'> {buildTimezoneString( new Date( props.time ), props.tz )} </Typography>
            </div>

            <div className="apptSummaryBackButtonWrapper">
                <div className="apptSummaryBackButton" onClick={handleClickBack}>
                    <MdChevronLeft></MdChevronLeft>
                    <Typography variant='body1'> Back </Typography>
                </div>
                
            </div>
        </div>
    );
}
