    // Determine text color for selected calendar days and button text (based on bp's primary color)

    function getRGB( c : string ) {
      const cString = c.toString();
        return parseInt( cString, 16 );
      }

    function getsRGB( c : string ) {
      return getRGB( c ) / 255 <= 0.03928 ?
        getRGB( c ) / 255 / 12.92 :
        Math.pow( ( getRGB( c ) / 255 + 0.055 ) / 1.055, 2.4 );
    }

    // Gets relative luminance values of a hex color.
    // Relative luminance is a measure of how bright a color is perceived to the human eye
    function getLuminance( hexColor : string ) {
      return (
        0.2126 * getsRGB( hexColor.substr( 1, 2 ) ) +
        0.7152 * getsRGB( hexColor.substr( 3, 2 ) ) +
        0.0722 * getsRGB( hexColor.substr( -2 ) )
      );
    }

    function getContrast( foreground : string, background : string ) {
      const L1 = getLuminance( foreground );
      const L2 = getLuminance( background );
      return ( Math.max( L1, L2 ) + 0.05 ) / ( Math.min( L1, L2 ) + 0.05 );
    }

    export function getTextColor( bgColor : string ) {
      const whiteContrast = getContrast( bgColor, '#ffffff' );
      // Normally we would use #00000 as the default here, but it seems like product wants our text to switch to white "sooner" than normal so going a little lighter here
      const blackContrast = getContrast( bgColor, '#444444' );

      return whiteContrast > blackContrast ? '#ffffff' : '#000000';
    }
