import React from 'react'
import { FaRegCalendarCheck, FaRegCalendarAlt, FaRegClock, FaMapMarkerAlt } from 'react-icons/fa';
import { anchorifyText, createGoogleMapsUrl } from '../../utils/linkUtilities';
import { Markup } from 'interweave';

/**
 * Displays the event info in a list with icons
 */
interface IconApptSummaryProps {
    eventTitle : string;
    eventTime : string; 
    eventDate : string;
    eventLocation : string
    children ?: []
}
export function IconApptSummary( props: IconApptSummaryProps) {
    let { eventTitle, eventTime, eventDate, eventLocation } = props;
    const eventLocationLink = ( eventLocation ) ? createGoogleMapsUrl( eventLocation ) : '';
    return (
        <>
        <div className='bookingConfirmationPage-wrapper'>
            <div className='booking-confirmation-page__icons'>
                <div className='booking-confirmation-page__icon-row'>
                    <FaRegCalendarCheck className='booking-confirmation-page__icon'/>
                    <p><b>{eventTitle}</b></p>
                </div>
                { !eventTime ? '' :
                    <div className='booking-confirmation-page__icon-row'>
                        <FaRegClock className='booking-confirmation-page__icon'/>
                        <p>{eventTime}</p>
                    </div>
                }
                { !eventDate ? '' :
                    <div className='booking-confirmation-page__icon-row'>
                        <FaRegCalendarAlt className='booking-confirmation-page__icon'/>
                        <p>{eventDate}</p>
                    </div>
                }
                { !eventLocation ? '' : 
                    <div className='booking-confirmation-page__icon-row'>
                        <FaMapMarkerAlt className='booking-confirmation-page__icon'/>
                        <p><b><Markup content={ anchorifyText(eventLocation, '<a target="_blank" href="' + eventLocationLink + '">') }/></b></p>
                    </div>
                }
            </div>
        </div>
        </>
    );
}

export default IconApptSummary;