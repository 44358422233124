export const states = [
    {
        value: 'AL',
        label: 'Alabama'
    },
    {
        value: 'AK',
        label: 'Alaska'
    },
    {
        value: 'AZ',
        label: 'Arizona'
    },
    {
        value: 'AR',
        label: 'Arkansas'
    },
    {
        value: 'CA',
        label: 'California'
    },
    {
        value: 'CO',
        label: 'Colorado'
    },
    {
        value: 'CT',
        label: 'Connecticut'
    },
    {
        value: 'DE',
        label: 'Delaware'
    },
    {
        value: 'DC',
        label: 'D.C.'
    },
    {
        value: 'FL',
        label: 'Florida'
    },
    {
        value: 'GA',
        label: 'Georgia'
    },
    {
        value: 'HI',
        label: 'Hawaii'
    },
    {
        value: 'ID',
        label: 'Idaho'
    },
    {
        value: 'IL',
        label: 'Illinois'
    },
    {
        value: 'IN',
        label: 'Indiana'
    },
    {
        value: 'IA',
        label: 'Iowa'
    },
    {
        value: 'KS',
        label: 'Kansas'
    },
    {
        value: 'KY',
        label: 'Kentucky'
    },
    {
        value: 'LA',
        label: 'Louisiana'
    },
    {
        value: 'ME',
        label: 'Maine'
    },
    {
        value: 'MD',
        label: 'Maryland'
    },
    {
        value: 'MA',
        label: 'Massachusetts'
    },
    {
        value: 'MI',
        label: 'Michigan'
    },
    {
        value: 'MN',
        label: 'Minnesota'
    },
    {
        value: 'MS',
        label: 'Mississippi'
    },
    {
        value: 'MO',
        label: 'Missouri'
    },
    {
        value: 'MT',
        label: 'Montana'
    },
    {
        value: 'NE',
        label: 'Nebraska'
    },
    {
        value: 'NV',
        label: 'Nevada'
    },
    {
        value: 'NH',
        label: 'New Hampshire'
    },
    {
        value: 'NM',
        label: 'New Mexico'
    },
    {
        value: 'NJ',
        label: 'New Jersey'
    },
    {
        value: 'NY',
        label: 'New York'
    },
    {
        value: 'NC',
        label: 'North Carolina'
    },
    {
        value: 'ND',
        label: 'North Dakota'
    },
    {
        value: 'OH',
        label: 'Ohio'
    },
    {
        value: 'OK',
        label: 'Oklahoma'
    },
    {
        value: 'OR',
        label: 'Oregon'
    },
    {
        value: 'PA',
        label: 'Pennsylvania'
    },
    {
        value: 'PR',
        label: 'Puerto Rico'
    },
    {
        value: 'RI',
        label: 'Rhode Island'
    },
    {
        value: 'SC',
        label: 'South Carolina'
    },
    {
        value: 'SD',
        label: 'South Dakota'
    },
    {
        value: 'TN',
        label: 'Tennessee'
    },
    {
        value: 'TX',
        label: 'Texas'
    },
    {
        value: 'UT',
        label: 'Utah'
    },
    {
        value: 'VT',
        label: 'Vermont'
    },
    {
        value: 'VA',
        label: 'Virginia'
    },
    {
        value: 'WA',
        label: 'Washington'
    },
    {
        value: 'WV',
        label: 'West Virginia'
    },
    {
        value: 'WI',
        label: 'Wisconsin'
    },
    {
        value: 'WY',
        label: 'Wyoming'
    },
    {
        value: 'AB',
        label: 'Alberta'
    },
    {
        value: 'BC',
        label: 'British Columbia'
    },
    {
        value: 'MB',
        label: 'Manitoba'
    },
    {
        value: 'NB',
        label: 'New Brunswick'
    },
    {
        value: 'NL',
        label: 'Newfoundland and Labrador'
    },
    {
        value: 'NS',
        label: 'Nova Scotia'
    },
    {
        value: 'NT',
        label: 'Northwest Territories'
    },
    {
        value: 'NU',
        label: 'Nunavut'
    },
    {
        value: 'ON',
        label: 'Ontario'
    },
    {
        value: 'PE',
        label: 'Prince Edward Island'
    },
    {
        value: 'QC',
        label: 'Quebec'
    },
    {
        value: 'SK',
        label: 'Saskatchewan'
    },
    {
        value: 'YT',
        label: 'Yukon'
    },
    {
        value: 'ACT',
        label: '(AU) Australian Capital Territory'
    },
    {
        value: 'NSW',
        label: '(AU) New South Wales'
    },
    {
        value: 'VIC',
        label: '(AU) Victoria'
    },
    {
        value: 'QLD',
        label: '(AU) Queensland'
    },
    {
        value: 'AU_NT',
        label: '(AU) Northern Territory'
    },
    {
        value: 'AU_WA',
        label: '(AU) Western Australia'
    }, {
        value: 'SA',
        label: '(AU) South Australia'
    },
    {
        value: 'TAS',
        label: '(AU) Tasmania'
    }, {
        value: 'GP',
        label: '(ZA) Gauteng'
    }, {
        value: 'WP',
        label: '(ZA) Western Cape'
    }, {
        value: 'EC',
        label: '(ZA) Eastern Cape'
    }, {
        value: 'KZN',
        label: '(ZA) KwaZulu Natal'
    },
    {
        value: 'NW',
        label: '(ZA) North West'
    },
    {
        value: 'AF_NC',
        label: '(ZA) Northern Cape'
    }, {
        value: 'MP',
        label: '(ZA) Mpumalanga'
    }, {
        value: 'FS',
        label: '(ZA) Free State'
    }, {
        value: '_NOTLISTED_',
        label: 'My State is not listed'
    }
];
