import request from './request';

import { getBrowserTimezone } from '../utils/dateTimeHelpers';

const id = window.bookingPageData.id;
export default class BookingPageService {

    static getAvailableDays( year : string, month: string, tz ?: string ) {
      if ( !tz ) {
        tz = getBrowserTimezone();
      }

      return request( {
        url: `/s/bookingAvailability?id=${id}&month=${year}-${month}&tz=${tz}`,
        method: 'GET',
        cache: false
      } )
    }

    static getListOfTimes( year: string, month: string | number, day: string | number, tz ?: string ) {
      if ( !tz ) {
        tz = getBrowserTimezone();
      }

      return request( {
          url: `/s/bookingAvailability?id=${id}&date=${year}-${month}-${day}&tz=${tz}`,
          method: 'GET',
          cache: false
      } );
    }

    static getTimeConfirmation( year: string, month: string | number, day: string | number, time: string | number, tz ?: string ) {
      if ( !tz ) {
        tz = getBrowserTimezone();
      }

      return request( {
        url: `/s/bookingAvailability?id=${id}&date=${year}-${month}-${day}&time=${time}&tz=${tz}`,
        method: 'GET',
        cache: false
      } );
    }

    static save( values: {} ) {
      return request( {
        url: `/s/bookingRequest`,
        method: 'POST',
        cache: false,
        data: values,
        headers: {
          "Content-Type": "multipart/form-data",
        }
      } )
    }

    static cancel( eventID: string, hash: string ) {
      return request( {
        url: `/s/modify?eventID=${eventID}&hash=${hash}&type=cancel`,
        method: 'POST',
        cache: false
      } );
    }

    static reschedule( eventID: string, hash: string ) {
      return request( {
        url: `/s/modify?eventID=${eventID}&hash=${hash}&type=reschedule`,
        method: 'POST',
        cache: false
      } );
    }

    static getCancelData( eventID: string, hash: string ) {
      return request( {
        url: `/s/modify?eventID=${eventID}&hash=${hash}`,
        method: 'GET',
        cache: false
      } )
    }
}