import React from 'react';
import {
FilledInput,
FormControl,
FormHelperText,
InputLabel,
Select
} from '@mui/material';
import './NativeSelect.css';

declare interface NativeSelectProps {
  prefill ?: string
  label: string
  type: string
  // Menu is really a JSON object
  menu: string
  error: boolean
  helperText: string
  name: string
  inputProps: any
}

export function NativeSelect( props: NativeSelectProps ) {
    const handleChangeMultiple = ( event : any ) => {
      const { options } = event.target;
      const value = [];
      for ( let i = 0, l = options.length; i < l; i += 1 ) {
        if ( options[ i ].selected ) {
          value.push( options[ i ].value );
        }
      }
    };
    const label = props.label;
    const type = props.type;
    const menu = JSON.parse( props.menu );

    return (
        <FormControl sx={{ m: 1 }} fullWidth className="nativeSelect">
            <InputLabel
                htmlFor={`${type}-label`}
                id={`${type}-label`}
                error={props.error} title={label}>
                  {label}
                </InputLabel>

            <Select
                name={props.name}
                multiple={true}
                native={true}
                // This notched attr doesnt do anything, but defining it removes an error so enjoy
                notched={true}
                input={<FilledInput />}
                label={type}
                id={type}
                onChange={handleChangeMultiple}
                defaultValue={props.prefill}
                error={props.error}
                inputProps={props.inputProps}
            >
                { menu.map( ( item: {label: string, value: string}, i : number ) => (
                    <option
                        key={`${i}`}
                        value={item.value}
                    >
                        {item.label}
                    </option>
                ) )}
            </Select>

            {props.error && props.helperText ?
              <FormHelperText>
                  {props.helperText}
              </FormHelperText> :
                null
            }

      </FormControl>
    );
}
