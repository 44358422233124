// Matches any string starting with http,https,www,tel and sms to the next white space
// its very greedy since all we care about is finding potential matches
// tests https://regexr.com/67r0s
/*
    Text http://chart.apis.google.com/chart?chs=500x500&chma=0,0,100,100

    more text http://www.chart.apis.google.com/chart?chs=500x500&chma=0,0,100,100

    text again http://www.domain.com?test=1
    text again http://www.domain.com#!/test=1

    more text www.google.com?test=0.1

    email mailto:jesse@ontraport.com
    text mesasge sms:550555555


    file transfer protocol ftp://domain.com?document=test

    <img href="https://image.com/?ting=1">
    <a href="https://image.com/thing=1">https://google.com?</a>

    telephone tel:515845549
*/
const urlFinderRegex = /((?:(?:https?):\/\/|www\.|mailto:|tel:|sms:)(?:\S)+)/igm;

const matchAnchorTagsRegex = /(?=<a).*?<\/a>/g;

function sanitizeHTML( html : string ) {

    let t = document.createTextNode( html );
    let p = document.createElement( 'p' );
    p.appendChild( t );
    return p.innerHTML;
}

type LinkifyTextOptions = {
    externalIcon ?: string;
    sanitize ?: boolean;
    stringifyComments ?: boolean;
}

/**
 *  Any URLs detected in the passed in string will be wrapped by anchor tags that will open the url in a new tab when clicked.
 *  Any HTML in the string will be escaped, and the only remaining HTML will be the inserted external link anchors.
 *
 *  This function is used in text input fields, long text input fields, url type fields, notes, and tasks.
 *
 * @param   textPotentiallyHtml {string} The text data from the field.  It may contain HTML, which will be escaped by this function.
 * @param   options   object containing the following options:
 *                      includeExternalIcon {boolean} default false - whether or not to include an op-icon representing an external link
 *                      sanitize {boolean} default true - whether or not to sanitize the first param or not.
 *                                                        If HTML that is expected to render is passed as the 1st parameter, then
 *                                                        this needs to be set to false.  Existing <img> and <a> tags in the HTML will
 *                                                        retained and render properly if sanitize is set to false.
 *
 */
export function linkifyText( textPotentiallyHtml : string|number, options ?: LinkifyTextOptions ) {

    let anchorTemplateString = '<a class="op-ext-link" target="_blank" href="$1" onclick="event.stopPropagation();">$1';

    if ( options?.externalIcon ) {
        anchorTemplateString += ' ' + options.externalIcon;
    }

    anchorTemplateString += '</a>';

    if ( textPotentiallyHtml === 0 ) {
        return '0';
    }

    if ( !textPotentiallyHtml ) {
        return '';
    }

    // Ensure this is a string.
    textPotentiallyHtml += '';

    let tempDiv = document.createElement( 'div' );

    textPotentiallyHtml = String( textPotentiallyHtml );
    // If we don't replace &gt; and &lt; the regex will match the '&' character as part of the url because '&' is a valid url character.
    // by swapping in a token wrapped in '{}' we can dodge the regex url matching.
    textPotentiallyHtml = textPotentiallyHtml.replace( /&lt;/g, '{lt}' );
    textPotentiallyHtml = textPotentiallyHtml.replace( /&gt;/g, '{gt}' );

    // If there are any valid ampersend signs
    textPotentiallyHtml = textPotentiallyHtml.replace( /&amp;/g, '{amp}' );

    // If a query string contains an & we need to mark it so when we pull the result(do to encoding ) out its not an &amp; and breaks the url
    textPotentiallyHtml = textPotentiallyHtml.replace( /&/g, '{charAmp}' );


    // Escape the input
    let origText = ( options?.sanitize != false || !options ) ? sanitizeHTML( textPotentiallyHtml ) : textPotentiallyHtml;

    // Regex looks for all <script> tags.
        // `.` => any character but a newline, e.g. the closing arrow bracket on the opening script tag `>`
        // `\n` => unix newline
        // `\r` => return character
        // `\r\n` => windows newline
    if ( options?.stringifyComments ) {
        // Stringify the comments so they still appear in iframes, otherwise they will get rendered as html
        const commentsRegex = /(<!-- (.|\n|\r|\r\n)* -->)/gi;

        for ( const comment of origText.match( commentsRegex ) ?? [] ) {
            origText = origText.replace( comment, sanitizeHTML( comment ) );
        }
    }

    const scriptRegex = /(<script(.|\n|\r|\r\n)*>(.|\n|\r|\r\n)*<\/script>)/gi;

    // Stringify the script tags, that way they still appear as text but they're not rendered
    for ( const scriptTag of origText.match( scriptRegex ) ?? [] ) {
        origText = origText.replace( scriptTag, sanitizeHTML( scriptTag ) );
    }

    // If we are not sanitizing this html, then we also want to make sure to leave existing <img> and <a> tags alone.
    let foundTags : Dictionary<string> = {};
    if ( options?.sanitize === false ) {

        // Regex looks for all <img> and <a> tags.
        const imgAnchorRegex = /(<a.+<\/a>)|(<img[^>]+>)/gi;
        const regexMatches = origText.match( imgAnchorRegex ) ?? [];

        if ( regexMatches.length > 0 ) {
            for ( let i = 0; i < regexMatches.length; i++ ) {

                // Store the found results and replace with a placeholder for later restore.
                const tagPlaceholder = `{*tag_${i}*}`;
                foundTags[ tagPlaceholder ] = regexMatches[ i ];
                origText = origText.replace( regexMatches[ i ], tagPlaceholder );
            }
        }
    }

    // Replace all of the "valid" links
    origText = origText.replace( urlFinderRegex, anchorTemplateString );


    tempDiv.innerHTML = origText;

    tempDiv.querySelectorAll( 'a.op-ext-link' ).forEach( ( anchor ) => {
        const href = anchor.getAttribute( 'href' );
        if ( href && href.substr( 0, 4 ) == 'www.' ) {
            anchor.setAttribute( 'href', 'https://' + href );
        }
    } );


    let val = tempDiv.innerHTML;

    // Restore any tags that had placeholders.
    if ( Object.keys( foundTags ).length > 0 ) {
        for ( const [ key, value ] of Object.entries( foundTags ) ) {
            if ( value || value === '' ) {
                val = val.replace( key, value );
            }
        }
    }

    // Restore any tokenized values
    val = val.replace( /{charAmp}/g, '&' );
    val = val.replace( /{amp}/g, '&amp;' );
    val = val.replace( /{lt}/g, '&lt;' );
    val = val.replace( /{gt}/g, '&gt;' );

    return val;
}

/**
 *  Insert anchors around each section of text that is not part of an external link.
 *  This is needed for fields in collections that drill
 *  down when the field also has external links in the data, because you can't have nested anchor tags.
 *
 *
 * @param data {string} The text data from the field.
 * @param openAnchorHTML {string} A string that will be used as the opening anchor tag for each inserted drill down links.
 */
export function anchorifyText( data : string, openAnchorHTML : string, externalIcon ?: string ) {
    let returnVal = data;

    // A merge field e.g [First Name] isnt going to work in an href
    if ( !containValidMergeFields( data ) ) {
        const linkifyOptions : LinkifyTextOptions = {};

        if ( externalIcon ) {
            linkifyOptions.externalIcon = externalIcon;

        }

        returnVal = linkifyText( data, linkifyOptions );
    }

    if ( returnVal.indexOf( '<a' ) > -1 ) {
        // If the entire string is exactly one link, just return that instead of adding extra empty links around it
        if ( returnVal.startsWith( '<a' ) && returnVal.endsWith( 'a>' ) ) {
            return returnVal;
        }

        var openAnchorToken = '{oA}';
        var closeAnchorToken = '{cA}';

        // This inserts a closing anchor token before eatch external link, and an open anchor token after.
        // e.g. foo [external-link] bar  =>   foo</a>[external-link]<a>bar
        var result = returnVal.replace( matchAnchorTagsRegex, ( match, $1 ) => {
            return closeAnchorToken + match + openAnchorToken;
    } );

        // The following line opens the first closing anchor token, and closes the last open anchor token.
        // in addition, it replaces the anchor tokens with action anchor tags (using the passed in openAnchorHTML)
        returnVal = ( openAnchorToken + result + closeAnchorToken ).replace( /{oA}/g, openAnchorHTML ).replace( /{cA}/g, '</a>' );
    } else {
        // If no external links were inserted by linkifyText, then we just create a single anchor around the data.
        returnVal = openAnchorHTML + returnVal + '</a>';
    }

    return returnVal;
}


export function createGoogleMapsUrl( location : string ) {
    let locationUrl = '';
    let urlMatch = location.match( /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g ),
        splitLocation = location.split( ':' );
    if ( urlMatch !== null ) {
        // The location is a URL so just use it as is.
        locationUrl = location;
    } else if ( splitLocation.length ) {
        // If theres an index 1, this location had a name and address, so use just the address if its there
        const locationToUse = splitLocation[ 1 ] ? splitLocation[ 1 ] : splitLocation[ 0 ];
        let address = encodeURI( locationToUse );
        locationUrl = 'https://www.google.com/maps/search/?api=1&query=' + address;
    }

    return locationUrl;
}


// @TODO: Remove everything below this line and readd the import below. The current self scheduling app build doesn't let you reach outside of the src folder.
//        The build recommends "add a symlink to it from project's node_modules/", but will need to figure that out later as techdebt.
// import { containValidMergeFields } from '@root/ontraport.validators/ontraport.validationFunctions';

export const MergeFieldRegex = /^(\[)([^\[])*(\])/g;

/**
 * Returns true if the given string is a single merge field or a bunch of chained merge fields
 * [Name] -> passes
 * [First Name][Last Name] -> passes
 * SomeOtherData[Name] -> fails
 *
 * @param val : String. The string to check if it's a merge field
 * @param mergeFieldRegex : Regex. The regular expression used to determine if it's a merge field. The default
 * value is
 *      /^(\[)([^\[])*(\])/g
 *
 * Setting the global rule flag (g) means it will find all of the possible matching merge fields
 * Capture groups are as follows
 * // 1. Match the opening left square bracket => (\[)
 * // 2. Match any character but the right, closing square bracket 0 -> many times => ([^\[])*
 * // 3. Match the closing right square bracket => (\])
 * The leading ^ makes the reg ex expect the string to start as a merge field.
 * So `[Name]` passes while
 * `SomeOtherData[Name]` fails
 *
 */
export function isMergeField( val : string, mergeFieldRegex : RegExp = MergeFieldRegex ) {
    // If val isn't a string, then theres's no merge field, so return false.
    if ( typeof val !== 'string' ) {
        return false;
    }

    // In case it doesn't match anything we'll return an iterable so that for of doesn't fail to execute
    const matches = val?.match( mergeFieldRegex );

    if ( !matches ) {
        return false;
    }

    for ( let mergeFieldString of matches ) {

        // The resulting array is the set of matching mergefields with their brackets
        // e.g. `[Membership site][#asdsads#######] adadsdsad ` => [ '[Membership site]', '[#asdsads#######]' ]
        if ( mergeFieldString ) {

            // We'll take the substring of all of the characters between the opening and
            // closing square brackets and test the resulting string
            const mergeFieldWithoutBrackets = mergeFieldString.substring( 1, mergeFieldString.length - 1 );

            // If after removing all the whitespace characters we don't have a length,
            // then we know that we don't have a valid merge field.
            if ( !( mergeFieldWithoutBrackets.replace( /[\s]*/g, '' ).length ) ) {
                return false;
            }

        }

    }

    return true;
}

/**
 * Returns true if the given string is a single merge field or a bunch of chained merge fields. It also
 * allows merge fields to be present anywhere in the string. Not just at the beginning of it.
 * [Name] -> passes
 * [First Name][Last Name] -> passes
 * SomeOtherData[Name] -> passes
 *
 * @param val : String. The string to check if it's a merge field
 *
 */
export function containValidMergeFields( val : string ) {
    // Setting the global rule flag (g) means it will find all of the possible matching merge fields.
    // Unlike the default regex of .mergefield, this one does not expect the string to begin as a merge field.
    // So this allows a merge field to appear in the middle of the string.
    // So both `[Name]` and `SomeOtherData[Name]` will pass
    const containsMergeFieldRegex = /(\[)([^\[])*(\])/g;

    return isMergeField( val, containsMergeFieldRegex );
}
