import {
Button,
Checkbox,
FilledInput,
FormControl,
FormControlLabel,
FormHelperText,
InputAdornment,
InputLabel,
TextField
} from '@mui/material';
import { CustomSelect } from '../CustomSelect/CustomSelect';
import { BasicDatePicker } from '../BasicDatePicker/BasicDatePicker';
import { NativeSelect } from '../NativeSelect/NativeSelect';
import { useForm } from 'react-hook-form';
import BookingPageService from '../../api/BookingPageService';
import { ResponseDialog, ResponseDialogProps } from '../ResponseDialog/ResponseDialog';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Form.css';
import React from 'react';

interface FormProps {
    time: number;
    tz: string;
    reschedule ?: string
    rescheduleHash ?: string
    formSubmittingHandler ?: ( isSubmitting:boolean ) => void
}

interface Field {
    name: string;
    field: string;
    data_type?: string;
    required?: number;
    prefill?: string;
    extra_data?: string
    value ?: string | number;
    isHidden ?: boolean;
    field_label ?: string;
}

interface SaveResponseData {
    logo ?: string;
    host_name ?: string;
    booking_page_title ?: string;
    event_time ?: string;
    event_date ?: string;
    event_location ?: string;
}

export interface FormValues {
    // We dont know the form fields ahead of time
    [key: string]: any;
}
export function Form(props: FormProps) {
    const time = props.time;
    const timezone = props.tz;
    let fields : Array<Field>= [];
    if( window.bookingPageData.custom_fields ) {
        fields = window.bookingPageData.custom_fields.map( (field ) => {
            return JSON.parse( field )
        } );
    }
    

    const hiddenFields : Array<Field> = window.bookingPageData.form_settings.hidden_fields;

    if( hiddenFields ) {
        // put hiddenFields in fields so we only have 1 array to iterate through for the form
        for ( let i = 0; i < hiddenFields.length; i++ ) {
            hiddenFields[ i ].isHidden = true
            fields.push( hiddenFields[ i ] );
        }
    }

    // Check for sess_cookie and that as a hidden field if needed
    function getCookie(cookie: string) {
        let name = cookie + '=';
        let cookieSplit = document.cookie.split( ';' );
        for( let i = 0; i < cookieSplit.length; i++ ) {
            let char = cookieSplit[ i ];
            while ( char.charAt( 0 ) == ' ' ) {
                char = char.substring( 1 );
            }
            if (char.indexOf( name ) == 0) {
                return char.substring( name.length, char.length );
            }
        }
        return '';
    }

    const sess_ = getCookie( 'sess_' );
    if ( sess_ ) {
        const sessObj: Field = {
            isHidden: true,
            field: 'sess_',
            value: sess_,
            name: 'sess_'
        }
        fields.push( sessObj );
    }

    // Add the time to fields so it gets added to form as a hidden field
    fields.push( {
        name: 'time', 
        field: 'time',
        isHidden: true,
        // Convert timestamp back to 10 digit for unix time for BE
        value: Math.floor( time / 1000 )
    } );

    // Add timezone to form so BE can set the time of the meeting correctly
    fields.push( {
        name: 'tz',
        field: 'tz',
        value: timezone,
        isHidden: true
    } );

    // Also add this as "timezone" so we set the contact's timezone profile.
    fields.push( {
        name: 'timezone',
        field: 'timezone',
        value: timezone,
        isHidden: true
    } );

    // Add the reschedule event ID to fields
    if ( props.reschedule ) {
        fields.push( {
            name: 'reschedule', 
            field: 'reschedule',
            isHidden: true,
            value: props.reschedule
        } )
    }

    // Add the reschedule hash to fields
    if ( props.rescheduleHash ) {
        fields.push( {
            name: 'hash', 
            field: 'hash',
            isHidden: true,
            value: props.rescheduleHash
        } )
    }

    type CheckboxVal = { [key : string] : boolean };
    const initCheckboxVal:CheckboxVal = {};

    // This object is needed for the date and date time inputs
    const defaultValues : FormValues = {};

    for ( let i = 0; i < fields.length; i++ ) {
        const field = fields[ i ];

        // Spoof the prefill data for now
        const prefills : FormValues = window.bookingPageData?.prefills || {};

        // Check if there is any property within the prefill object that matches the field.
        if ( prefills[ field.name ] ) {
            if ( field.data_type === 'fulldate' ) {
                defaultValues[ field.field ] = new Date( prefills[ field.name ] );
            } else if( field.data_type === 'timestamp' ) {
                defaultValues[ field.field ] = new Date( Number(prefills[ field.name ]) * 1000 );
            } else  {
                // If so then save it for later so the field has that value to use
                field.prefill = prefills[ field.name ];

                // Initialize the checkbox state of a prefilled checkbox
                if( field.data_type === 'check' && prefills[ field.name ] == 1 ) {
                    initCheckboxVal[ field.field ] = true;
                }
            }
        } else {
            // Date fields are finicky in that they will show a value even if no default value is set, 
            // but when you go to submit they wont be included. So we need to set a default value even if there is none.
            if ( field.data_type === 'fulldate' || field.data_type === 'timestamp' ) {
                defaultValues[ field.field ] = new Date();
            } else {
                field.prefill = '';
            }
        }
    }

    // Object used for tracking the checkbox state. Needed for validation because material ui library doesn't
    // have checkbox validation
    const [ checkboxVals, setCheckboxVals ] = useState( initCheckboxVal );

    // Set up the form register now that we have the correct initial data
    const { register, handleSubmit, control, formState: { errors } } = useForm<FormValues>( { defaultValues: defaultValues } );

    const onSubmit = ( data: FormValues ) => {
            // Generate form data object for the BE
            const formData = new FormData();

            for( let key in data ) {
                const fieldVal = data[key];
                if( fieldVal instanceof Date ) {
                    const fieldDef = fields.find( field => field.field === key );
                    if( fieldDef?.data_type === 'timestamp' ) {
                        formData.append( key, '' + Math.floor( ( fieldVal ).getTime() / 1000 ) );
                    } else {
                        let dateString = ( fieldVal.getMonth() + 1 ) + '/' + fieldVal.getDate() + '/' + fieldVal.getFullYear();
                        formData.append( key, dateString );
                    }
                } else {
                    formData.append( key, fieldVal );
                }                
            }
            
            // Add in current URL for BE so they don't have to rely on HTTP referrer to determine domain owner
            formData.append( 'source_url', window.location.host + window.location.pathname );

            if( props.formSubmittingHandler ) {
                props.formSubmittingHandler( true );
            }
            

            BookingPageService.save( formData ) .then( ( res ) => {
                if ( res?.data?.redirect || res?.data?.is_default_redirect ) {
                    if( res.data.is_default_redirect ) {
                        let responseData:SaveResponseData = {};

                        try {
                            responseData = JSON.parse( res.data.data );
                        } catch( e ) {

                        }
                        
                        window.bookingPageData.eventLocation = responseData.event_location || '';
                        window.bookingPageData.hostName = responseData.host_name || '';
                        window.bookingPageData.dateTimestamp = time;
                        window.bookingPageData.timezone = timezone;

                        navigate( '/thank-you' );
                    } else {
                        window.location.replace( res.data.redirect );
                    }
                } else {
                    if( props.formSubmittingHandler ) {
                        props.formSubmittingHandler( false );
                    }

                    if( res?.code === 104 ) {
                        // Show req fields dialog
                        setReqFieldsDialogOpen( true );
                    } else if( res?.code === 1 ) {
                        setErrorDialogOpen( true );
                    } else {
                        // Show time unavailable dialog
                        setAvailabilityDialogOpen( true );
                    }
                }
            }, () => {
                // For any request failure, require a generic fail message
                if( props.formSubmittingHandler ) {
                    props.formSubmittingHandler( false );
                }

                setErrorDialogOpen( true );
            } );
    }

    // Generic error dialog state & handler
    const [ errorDialogOpen, setErrorDialogOpen ] = useState( false );

    // Required fields dialog state
    const [ reqFieldsDialogOpen, setReqFieldsDialogOpen ] = useState( false );

    // Time unavailable dialog state
    const [ availabilityDialogOpen,setAvailabilityDialogOpen ] = useState( false );


    // Function to redirect back to select a time page
    const navigate = useNavigate();
    const selectANewTime = () => {
        // Go back to appt time selection
        navigate( '/' );
    };

    let responseDialogProps : ResponseDialogProps = {
        open: errorDialogOpen,
        onClose: () => {
            setErrorDialogOpen( false );
        }
    };

    if( reqFieldsDialogOpen ) {
        responseDialogProps = {
            open: reqFieldsDialogOpen,
            onClose: ()=> {
                setReqFieldsDialogOpen( false );
            },
            title: 'Missing Required Fields',
            text: 'It appears that some required fields were missing. Please complete all required fields and resubmit.',
            buttonText: 'Close'
        }
    } else if( availabilityDialogOpen ) {
        responseDialogProps = {
            open: availabilityDialogOpen,
            onClose: () => {
                setAvailabilityDialogOpen( false );
            },
            onButtonClick: () => {
                setAvailabilityDialogOpen( false );
                selectANewTime();
            },
            title: 'Sorry, this date or time is no longer available',
            text: 'Please select a new date and time by clicking below.',
            buttonText: 'View available times'
        }
    }

        return (
            <form onSubmit={handleSubmit( onSubmit )} encType="multipart/form-data">
                <div>
                        {fields.map( ( field: Field, i: number ) => {
                            let fieldLabel = field.field_label || field.name;
                            let isRequired = 'This field is required.' ;
                            if ( !field.required || field.required == 0 ) {
                                isRequired = '';
                            }
                            if ( field.isHidden ) {
                                return (
                                    <input type="hidden" value={field.value} {...register(field.field)} />
                                )
                            }
                            switch ( field.data_type ) {
                                case 'text': {
                                    return (
                                        <div className="form-field" key={i}>
                                            <FormControl required={true} fullWidth>
                                                <TextField
                                                    label={`${fieldLabel}`}
                                                    InputLabelProps={{ title: fieldLabel }}
                                                    defaultValue={field.prefill}
                                                    variant="filled"
                                                    {...register( field.field, { required: isRequired } )}
                                                    error={Boolean( errors[ field.field ] )}
                                                    helperText= {errors[ field.field ]?.message as string}
                                                />
                                            </FormControl>
                                        </div>

                                    );
                                } case 'check': {
                                    const validationFailed = !!(field.required && field.field in checkboxVals && !checkboxVals[ field.field]);
                                    return (
                                        <div className="form-field form-field--checkbox" key={i}>
                                            <FormControl >
                                                <FormControlLabel
                                                    {...register( field.field, { validate: ()=>{
                                                        if( !field.required ) {
                                                            return true;
                                                        } else {
                                                            // If the val just isn't in there, set it to false
                                                            // So validation message appears
                                                            if(!(field.field in checkboxVals) ) {
                                                                const newCheckboxVals = {...checkboxVals};
                                                                newCheckboxVals[ field.field ] = false;
                                                                setCheckboxVals(newCheckboxVals);
                                                                return false;
                                                            }
                                                            return !!checkboxVals[field.field];
                                                        }
                                                    } } )}
                                                    control={<Checkbox defaultChecked={!!field.prefill}/>}
                                                    title={fieldLabel}
                                                    label={fieldLabel}
                                                    onChange={(ev:React.ChangeEvent<HTMLInputElement>)=>{ 
                                                        const newCheckboxVals = {...checkboxVals};
                                                        newCheckboxVals[ field.field ] = ev.target.checked;
                                                        setCheckboxVals(newCheckboxVals);
                                                    }}
                                                    className={(validationFailed) ? 'check-box--invalid' : ''}
                                                />
                                                {  validationFailed ?  
                                                    <FormHelperText style={{ color: 'rgb(211, 47, 47)' }}>
                                                        This field is required.
                                                    </FormHelperText>  
                                                : 
                                                    ''
                                                }
                                            </FormControl>
                                        </div>
                                    );
                                } case 'country': {
                                    return (
                                        <div className="form-field" key={i}>
                                            <CustomSelect
                                                name={`${field.field}`}
                                                prefill={field.prefill}
                                                inputProps={register( field.field, {required: isRequired} )}
                                                error={Boolean( errors[ `${field.field}` ] )}
                                                helperText={errors[ `${field.field}` ]?.message}
                                                label={fieldLabel}
                                                type={field.data_type}
                                            />
                                        </div>
                                    );
                                } case 'fulldate' : {
                                    return (
                                        <div className="form-field" key={i}>
                                            <BasicDatePicker
                                                name={field.field}
                                                label={fieldLabel}
                                                inputProps={register( field.field)}
                                                control={control}
                                            ></BasicDatePicker>
                                        </div>
                                    );
                                } case 'timestamp': {
                                    return (
                                        <div className="form-field" key={i}>
                                            <BasicDatePicker
                                                name={field.field}
                                                inputProps={register( field.field )}
                                                control={control}
                                                isDateTime={true}
                                                label={fieldLabel}
                                            />
                                        </div>
                                    );
                                } case 'drop': {
                                    return (
                                        <div className="form-field" key={i}>
                                            <CustomSelect
                                                name={field.field}
                                                inputProps={register( field.field, {required: isRequired} )}
                                                prefill={field.prefill}
                                                error={Boolean( errors[ `${field.field}` ] )}
                                                helperText={errors[ `${field.field}` ]?.message}
                                                label={fieldLabel}
                                                menu={field.extra_data}
                                                type={field.data_type}
                                            />
                                        </div>
                                    );
                                } case 'email': {
                                    return (
                                        <div className="form-field" key={i}>
                                            <TextField
                                                id="filled-basic"
                                                variant="filled"
                                                fullWidth
                                                label={fieldLabel}
                                                InputLabelProps={{ title: fieldLabel }}
                                                defaultValue={field.prefill}
                                                {...register( field.field, {
                                                    required: {
                                                        value: isRequired ? true : false,
                                                        message: isRequired
                                                },
                                                    pattern: {
                                                    value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: 'Please enter a valid email'
                                                }
                                                } )}
                                                error={Boolean( errors[ `${field.field}` ] )}
                                                helperText={errors[ `${field.field}` ]?.message as string}
                                            />
                                        </div>
                                    );
                                } case 'list': {
                                    return (
                                        <div className="form-field form-field--list" key={i}>
                                            <NativeSelect
                                                name={`${field.field}`}
                                                label={fieldLabel}
                                                prefill={field.prefill}
                                                menu={field.extra_data as string}
                                                type={field.data_type}
                                                inputProps={register( field.field, {required: isRequired} )}
                                                error={Boolean( errors[ `${field.field}` ] )}
                                                helperText={errors[ `${field.field}` ]?.message as string}
                                            />
                                        </div>
                                    );
                                } case 'longtext': {
                                    return (
                                        <div className="form-field form-field--long-text" key={i}>
                                            <FormControl required={true} fullWidth>
                                                <TextField
                                                    label={fieldLabel}
                                                    InputLabelProps={{ title: fieldLabel }}
                                                    defaultValue={field.prefill || null}
                                                    variant="filled"
                                                    {...register( field.field, { required: isRequired} )}
                                                    error={Boolean( errors[ field.field ] )}
                                                    helperText={errors[ field.field ]?.message as string}
                                                    minRows={6}
                                                    maxRows={6}
                                                    multiline
                                                />
                                            </FormControl>
                                        </div>
                                    );
                                } case 'numeric': {
                                    return (
                                        <div className="form-field" key={i}>
                                            <FormControl required={true} fullWidth>
                                                <TextField
                                                    type="number"
                                                    label={fieldLabel}
                                                    InputLabelProps={{ title: fieldLabel }}
                                                    defaultValue={field.prefill || null}
                                                    variant="filled"
                                                    {...register( field.field, { required: isRequired } )}
                                                    error={Boolean( errors[ `${field.field}` ] )}
                                                    helperText={errors[ `${field.field}` ]?.message as string}
                                                />
                                            </FormControl>
                                        </div>
                                    );
                                } case 'phone': {
                                    return (
                                        <div className="form-field" key={i}>
                                            <FormControl required={true} fullWidth>
                                                <TextField
                                                    type="number"
                                                    label={fieldLabel}
                                                    InputLabelProps={{ title: fieldLabel }}
                                                    defaultValue={field.prefill || null}
                                                    variant="filled"
                                                    {...register( field.field, { required: isRequired } )}
                                                    error={Boolean( errors[ `${field.field}` ] )}
                                                    helperText={errors[ `${field.field}` ]?.message as string}
                                                />
                                            </FormControl>
                                        </div>
                                    );
                                } case 'price': {
                                    return (
                                        <div className="form-field" key={i}>
                                            <FormControl fullWidth>
                                                <InputLabel
                                                    htmlFor={field.field}
                                                    error={Boolean( errors[ field.field ] )}
                                                    title={fieldLabel}
                                                >
                                                    {fieldLabel}
                                                </InputLabel>
                                                <FilledInput
                                                    type="number"
                                                    min="0"
                                                    id={`${field.field}`}
                                                    defaultValue={field.prefill}
                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                    {...register( field.field, { required: isRequired } )}
                                                    error={Boolean( errors[ `${field.field}` ] )}
                                                ></FilledInput>

                                                {errors[ `${field.field}` ]?.message ?
                                                    <FormHelperText

                                                    >
                                                        {errors[ field.field ]?.message as string}
                                                    </FormHelperText> :

                                                    null
                                                }
                                            </FormControl>
                                        </div>
                                    );
                                } case 'sms': {
                                    return (
                                        <div className="form-field" key={i}>
                                            <FormControl required={true} fullWidth>
                                                <TextField
                                                    type="tel"
                                                    label={fieldLabel}
                                                    InputLabelProps={{ title: fieldLabel }}
                                                    defaultValue={field.prefill || null}
                                                    variant="filled"
                                                    {...register( field.field, {
                                                        required: {
                                                            value: isRequired ? true : false,
                                                            message: isRequired
                                                        }
                                                    }
                                                    )}
                                                    error={Boolean( errors[ field.field ] )}
                                                    helperText={errors[ field.field ]?.message as string}
                                                />
                                            </FormControl>
                                        </div>
                                    );
                                } case 'state': {
                                    return (
                                        <div className="form-field" key={i}>
                                            <CustomSelect
                                                name={field.field}
                                                inputProps={register( field.field, {required: isRequired} )}
                                                prefill={field.prefill}
                                                error={Boolean( errors[ field.field ] )}
                                                helperText={errors[ field.field ]?.message}
                                                label={fieldLabel}
                                                type={field.data_type}
                                            />
                                        </div>
                                    );
                                } case 'url': {
                                    return (
                                        <div className="form-field" key={i}>
                                            <FormControl required={true} fullWidth>
                                                <TextField
                                                    type="text"
                                                    label={fieldLabel}
                                                    InputLabelProps={{ title: fieldLabel }}
                                                    defaultValue={field.prefill || null}
                                                    variant="filled"
                                                    {...register( field.field, {
                                                        required: {
                                                            value: isRequired ? true : false,
                                                            message: isRequired
                                                        },
                                                        pattern: {
                                                            value: /^(ftp|http|https):\/\/[^ "]+$/,
                                                            message: 'Please enter a valid URL'
                                                        }
                                                    } )}
                                                    error={Boolean( errors[ field.field ] )}
                                                    helperText={errors[ field.field ]?.message as string}
                                                />
                                            </FormControl>
                                        </div>
                                    );
                                }
                            }
                        } )}
                        <div>
                            <Button variant="contained" color="primary" type="submit" className="btns form-submit-button" fullWidth>
                                {window.bookingPageData.submit_button_text ?? 'submit'}
                            </Button>
                        </div>
                </div>
                
                <ResponseDialog {...responseDialogProps} >
                </ResponseDialog>
            </form>
        );


}
