import React from 'react';
import { Header } from '../Header/Header';
import { Calendar } from '../Calendar/Calendar';
import TimePicker from '../TimePicker/TimePicker';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {parseDate} from '@internationalized/date';
import {ACTIONS, GetReducer} from '../../hooks/reducer';
import { ResponseDialog, ResponseDialogProps } from '../ResponseDialog/ResponseDialog';
import BookingPageService from '../../api/BookingPageService';
import { useQuery } from 'react-query';
import { getRelativeTimezoneOffset } from '../../utils/dateTimeHelpers';
import './BookingTimeSelectPage.css';

interface BookingTimeSelectPageProps {
    isSingleHost: boolean
    textColor: string
    primaryColor: string
}

interface CalendarValue {
    day: number;
    era: string
    month: number | string
    year: number
}

export function BookingTimeSelectPage( props: BookingTimeSelectPageProps ) {
    const navigate = useNavigate();

    // Get the month and possible date search params off the url
    const [ searchParams, setSearchParams ] = useSearchParams();

    let monthQuery: string | number | null = searchParams.get( 'month' );
    let yearQuery = null;

    if ( monthQuery ) {
        let isValidMonthParam = true;

        let separatedMonth = monthQuery.split( '-' );

        if( separatedMonth.length !== 2 ) {
            isValidMonthParam = false;
        }

        if( isValidMonthParam ) {
            monthQuery = Number( separatedMonth[ 1 ] );

            if( monthQuery === NaN || monthQuery < 1 || monthQuery > 12  ) {
                isValidMonthParam = false;
            }

            if( isValidMonthParam ) {
                yearQuery = Number( separatedMonth[ 0 ] );
                
                // Arbitrarily putting restrictions on the year. Sorry, no scheduling events past year 2500.
                // If this code lasts that long, I should have been paid more.
                if( yearQuery === NaN || yearQuery < 1900 || yearQuery > 2500 ) {
                    isValidMonthParam = false;
                }
            }
        }

        if ( !isValidMonthParam ) {
            // Use today if month or date is trash
            const today = new Date();
            monthQuery = today.getMonth() + 1;
            yearQuery = today.getUTCFullYear();
        }
    } else {
        // Use today if nothing available
        const today = new Date();
        monthQuery = today.getMonth() + 1;
        yearQuery = today.getUTCFullYear();
    }

    let dateQuery = searchParams.get( 'date' );
    if ( dateQuery ) {
        let dateSeparated = dateQuery.split( '-' );

        if( dateSeparated.length === 3 ) {
            dateQuery = dateSeparated[ 2 ];

            // Figure out what the highest possible day number is based on the month value
            let dateLimit = 31;
            if( monthQuery == 2 ) {
                // Account for leap year for february
                if( yearQuery && Number.isInteger( yearQuery / 4 ) ) {
                    dateLimit = 29;
                } else {
                    dateLimit = 28;
                }
                
            } else if( monthQuery == 4 || monthQuery == 6 || monthQuery == 9 || monthQuery == 11 ) {
                dateLimit = 30;
            }

            const dateNumber = Number( dateQuery );

            if( dateNumber === NaN || dateNumber < 1 || dateNumber > dateLimit ) {
                // If date is invalid, null it out
                dateQuery = null;
            }
        } else {
            // If date is invalid, null it out
            dateQuery = null;
        }     
    }

    if ( monthQuery.toString().length < 2 ) {
        monthQuery = `0${monthQuery}`;
    }

    let tzQuery = searchParams.get( 'tz' );

    // Use date information to set the initial state
    const [ state, dispatch ] = GetReducer( { monthQuery: monthQuery, dateQuery:dateQuery, yearQuery: yearQuery, tzQuery: tzQuery } );


    // Availability dialog state
    const [ open,setOpen ] = useState( false );

    // This will unselect the day/time, triggered from Timeunavailable dialog
    const selectANewDay = () => {
        dispatch( {
            type: ACTIONS.CHANGED_DATE,
            date: ''
        } );
    };

    // Response properties, current only used for time unavailable dialog
    let responseDialogProps : ResponseDialogProps = {
        open: open,
        onClose: () => {
            setOpen( false );
        },
        onButtonClick: () => {
            setOpen( false );
            selectANewDay();
        },
        title: 'Sorry, this date or time is no longer available',
        text: 'Please select a new date and time by clicking below.',
        buttonText: 'View available times'
    };
    

    // Request available times when we have a month selected and when page first loads
    const { isFetching: isFetchingDays, refetch: refetchAvailableDays } = useQuery(
        [ 'days' ],
        () => BookingPageService.getAvailableDays( state.year, state.month, state.tz ).then( ( res ) => {
            const availDays = res?.data || [];
            dispatch( {
                type: ACTIONS.CHANGED_AVAIL_DAYS,
                availableDays: availDays
            } );

            return availDays;
        } )
    )

    // Request available times when the day selected changes
    const { isFetching: isFetchingTimes, refetch } = useQuery(
        [ 'times' ],
        () => BookingPageService.getListOfTimes(state.year, state.month, state.date, state.tz ).then( ( res ) => {
            const availTimes = res?.data || [];
            dispatch( {
                type: ACTIONS.CHANGED_AVAIL_TIMES,
                availableTimes: availTimes
            } );
            if ( availTimes.length === 0 ) {
                setOpen( true );
            }
        } ),
        {
            cacheTime: 0,
            staleTime: 0,
            // This flag means that this request wont run on on initial load
            enabled: false,
        }
    );
    
    // Handler for clicking a new month in the calendar
    const handleUpdateMonth = ( ev: CalendarValue ) => {
        let updatedMonth = ev.month;

        if ( updatedMonth.toString().length === 1 ) {
            updatedMonth = `0${updatedMonth}`;
        }
        if ( updatedMonth == state.month ) {
            return;
        }
        
        // Set the new month on state
        dispatch( {
            type: ACTIONS.CHANGED_MONTH,
            month: updatedMonth,
            year: ev.year
        } );
    }

    
    // Handler for clicking a new date in calendar
    const handleUpdateDay = ( ev: CalendarValue ) => {
        let day = ev.day.toString();
        if ( day.toString().length === 1 ) {
            day = `0${day}`;
        }
        dispatch( {
            type: ACTIONS.CHANGED_DATE,
            date: day
        } );
    };

    // Handler for clicking a new time in the time picker
    const handleTimeClicked = ( ev: React.ChangeEvent<HTMLInputElement> ) => {
        // Sometimes the target ends up as the Typography element so need to grab the right one
        let clickedTime = ev.target.value;
        if ( !clickedTime ) {
            const clickedTimeEl = ev.target?.parentElement as HTMLInputElement;
            if (clickedTimeEl ) {
                clickedTime = clickedTimeEl.value;
            }

        }
        dispatch( {
            type: ACTIONS.CHANGED_TIME,
            time: clickedTime
        } );
    };

    const [ isAnimating, setAnimation ] = useState( false );
    // Request confirmation of availability when Confirm is clicked
    const {isFetching: isFetchingConfirmation, refetch: refetchTimeConfirmation } = useQuery(
        [ 'isTimeAvailable' ],
        () => BookingPageService.getTimeConfirmation( state.year, state.month, state.date, state.time, state.tz ).then( ( res ) => {
            const availTimes = res?.data || [];
            
            // The response will contain a single time if its available and be blank if not.
            if ( availTimes.length == 1  ) {

                const splitTime = state.time.split( ':' );

                const confirmedTimeDateObj = new Date( Number(state.year), Number(state.month) - 1, Number(state.date), Number( splitTime[ 0 ] ), Number( splitTime[ 1 ] ), 0 );

                const timezoneOffset = getRelativeTimezoneOffset( state.tz );

                // Offset the date based on the selected timezone.
                confirmedTimeDateObj.setMinutes(confirmedTimeDateObj.getMinutes() + timezoneOffset );

                dispatch( {
                    type: ACTIONS.CHANGED_CONFIRMED_TIME,
                    confirmedTime: confirmedTimeDateObj.getTime()
                } );
                // Then, animate the Calendar and TimePicker out to the left.
                setAnimation( true );
            } else {
                // Show time unavailable dialog
                setOpen( true );
            }
        } ), {
            enabled: false,
            refetchOnWindowFocus: false
        }
    );
    // Wait for the slide out animation to finish before navigating away. Otherwise you wont see the animation.
    const navigateToForm = () => {
        if ( state.confirmedTime ) {
            setTimeout( () => {
                let updatedSearchParams = new URLSearchParams( searchParams.toString() );
                navigate( `form?${updatedSearchParams.toString()}&time=${state.confirmedTime}` );
            }, 750 )
        }
    };

    // Time Confirmed change handler
    const handleTimeConfirmed = () => {
        refetchTimeConfirmation();
    };

    const handleTimezoneChanged = ( ev: React.ChangeEvent<HTMLSelectElement> ) => {
        let clickedTimezone = ev.target.value;
       
        dispatch( {
            type: ACTIONS.CHANGED_TIMEZONE,
            tz: clickedTimezone
        } );
    };

    useEffect( () => {
        // Update the month url query. Preserve all others.
        let updatedSearchParams = new URLSearchParams( searchParams.toString() );
        updatedSearchParams.set( 'month', `${state.year}-${state.month}` );
        setSearchParams( updatedSearchParams.toString() );

        // Get new days for this month
        refetchAvailableDays();
    }, [ state.month, state.year ] );

    useEffect( () => {
        if ( state.date ) {
            // Update the month and date url query. Preserve all others.
            let updatedSearchParams = new URLSearchParams( searchParams.toString() );
            updatedSearchParams.set( 'month', `${state.year}-${state.month}` );
            updatedSearchParams.set( 'date', `${state.year}-${state.month}-${state.date}` );
            updatedSearchParams.set( 'tz', state.tz );
            setSearchParams( updatedSearchParams.toString() );

            // Get new times for this day now that state was updated with the date
            refetch();
        } else if( state.date === '' ) {
            let updatedSearchParams = new URLSearchParams( searchParams.toString() );
            updatedSearchParams.delete( 'date' );

            setSearchParams( updatedSearchParams.toString() );
        }
    }, [ state.date, state.tz ] );

    useEffect( () => {
        if ( state.confirmedTime ) {
            navigateToForm()
        }
    }, [ state.confirmedTime ])


    return (
        <>
            <Header isConfirmation={false} />
            <div className={`bookingTimeSelectPage-wrapper ${isAnimating ? 'slide-out-left' : ''}`}>
                <div className="col-wrapper">
                    <div className="col-wrapper__column-left">
                        <Calendar
                            month={state.month}
                            date={state.date}
                            defaultValue={parseDate( `${state.year}-${state.month}-${state.date ? state.date : '01'}` )}
                            onChange={handleUpdateDay}
                            onFocusChange={handleUpdateMonth}
                            textColor={props.textColor}
                            primaryColor={props.primaryColor}
                            isFetching={isFetchingDays}
                            availableDays={(isFetchingDays) ? [] : state.availableDays}
                        />
                    </div>
                    <div className="col-wrapper__column-left">
                        <div className="header-divider mobile-only" style={{marginBottom:'18px'}}></div>
                        <TimePicker
                            date={state.date}
                            month={state.month}
                            year = {state.year}
                            time = {state.time}
                            timezone = {state.tz}
                            availableTimes ={ state.availableTimes }
                            handleTimeClicked={handleTimeClicked}
                            handleTimeConfirmed={handleTimeConfirmed}
                            handleTimezoneChanged={handleTimezoneChanged}
                            textColor={props.textColor}
                            isFetching={isFetchingTimes || isFetchingConfirmation}
                        />
                    </div>
                </div>

                <ResponseDialog {...responseDialogProps} >
                </ResponseDialog>
            </div>
        </>
    );
}
