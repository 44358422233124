import React from 'react';
import {
FilledInput,
FormControl,
FormHelperText,
InputLabel,
MenuItem,
Select
} from '@mui/material';
import { useState } from 'react';
import { countries } from '../../data/countries';
import { states } from '../../data/states';
import './CustomSelect.css';
import {UseFormRegisterReturn} from "react-hook-form";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

interface CustomSelectProps {
  label: string; 
  type: string; 
  prefill?: string;
  menu?: string;
  name: string;
  error: boolean;
  helperText: any;
  inputProps: UseFormRegisterReturn<string>;
  open ?: boolean;
  onClose ?: (ev: any) => void;
  onChange ?: (ev: any) => void;

}
export function CustomSelect( props: CustomSelectProps ) {
    const label = props.label;
    const type = props.type;
    const [ selectedItem, setSelectedItem ] = useState( props.prefill ?? '' );

    const handleMultiSelectChange = ( event: any ) => {
      const target = event.target;
      setSelectedItem(
        // On autofill we get a stringified value.
        target.value,
      );

      if ( props.onChange ) {
        props.onChange( event );
      }
    };

    let itemList = [];
    if ( type === 'country' ) {
      itemList = countries;
    } else if ( type === 'state' ) {
      itemList = states;
    } else if ( props.menu ) {
      itemList = JSON.parse( props.menu );
    }

    return (
        <FormControl sx={{ m: 1 }} fullWidth className='customSelect'>
            <InputLabel
                id={`${type}-label`}
                error={props.error} title={label}>
                  {label}
                </InputLabel>

            <Select
                name={props.name}
                labelId={`${type}-label`}
                id={type}
                value={selectedItem}
                onChange={handleMultiSelectChange}
                input={<FilledInput />}
                label={type}
                error={props.error}
                MenuProps={MenuProps}
                inputProps={props.inputProps}
                open={props.open}
                onClose={props.onClose}
            >
                { itemList.map( ( item:{label: string, value: string}, i: number ) => (
                    <MenuItem
                        key={`${i}`}
                        value={item.value}
                    >
                        {item.label}
                    </MenuItem>
                ) )}
            </Select>

            {props.error && props.helperText ?
              <FormHelperText>
                  {props.helperText}
              </FormHelperText> :
                null
            }

    </FormControl>
    );
}
