import axios from 'axios';

const client = (() => {
    return axios.create( {
        // The base url for us will be like tester.ontraportcom/meet-with-me/bookingAvailability
        baseURL: window.location.origin
    } )
})();
interface Options {
    url: string
    method: string
    cache: boolean
    data ?: {},
    headers?: any

}

const request = async function( options: Options ) {
    const onSuccess = function( response: any ) {
        // Destrucutre the response 
        return response.data;
    }

    const onError = function( error: any ) {
        return Promise.reject( error.response );
    }

    return client( options ).then( onSuccess ).catch( onError );
}

export default request;